import React from 'react';
import { renderToString } from 'react-dom/server';
import { Spacer } from '@rmstransactions/components';
import { serviceCenterPhoneNumber } from 'utils/shared/shared';
import {
  SERVICE_CENTRE_URL,
  NSW_APP_URL,
  DIGITAL_DRIVER_LICENCE_URL,
  INTERIM_DRIVER_LICENCE_URL,
  HEAVY_PENALTIES_URL,
  LATE_LICENCE_RENEWAL_URL,
  EYESIGHT_TEST_URL,
  HOW_DEMERIT_POINTS_WORK_URL,
  HOW_MEDICAL_REQUIREMENTS_WORK_URL,
  FITNESS_TO_DRIVE_ASSESMENT_PROCESS_URL,
  SUSPENSION_DISQUALIFICATION_URL,
  LODGE_APPEAL_ONLINE_URL,
  DRIVING_RECORD_ONLINE_URL,
  RENEW_LICENCE_URL,
  NSW_PHOTO_CARD_URL,
  RENEW_OR_UPGRADE_LICENCE_URL,
} from '../../../../constants/constants';
import { IInfo } from './helpers';

const Link = ({ href, label = '', children }) => (
  <a href={href} target='_blank' rel='noopener noreferrer' aria-label={label}>
    {children}
  </a>
);
const renewLicenceLink = (
  <a href={RENEW_LICENCE_URL} target='_blank' rel='noopener noreferrer'>
    renew your licence
  </a>
);
const licenceRenewalLink = (
  <Link href={RENEW_OR_UPGRADE_LICENCE_URL}>licence renewal</Link>
);
const licenceUpgradeLink = (
  <Link href={RENEW_OR_UPGRADE_LICENCE_URL}>upgrade</Link>
);

export const appealOnlineLink = (
  <Link href={LODGE_APPEAL_ONLINE_URL}>appeal online</Link>
);
export const serviceCenterLink = (
  <Link href={SERVICE_CENTRE_URL}>service centre</Link>
);
const serviceCenterLinkForExpired = renderToString(
  <Link href={SERVICE_CENTRE_URL}>service centre</Link>
);
const SNSWAppLink = <Link href={NSW_APP_URL}>SNSW app</Link>;

export const downloadDigitalDriverLicenceLink = (
  <Link
    href={DIGITAL_DRIVER_LICENCE_URL}
    label='External link - Download Service NSW App'
  >
    Digital Driver Licence
  </Link>
);

export const printInterimDriverLicenceLink = (
  <Link href={INTERIM_DRIVER_LICENCE_URL}>Interim Driver Licence</Link>
);

const heavyPenaltiesLink = renderToString(
  <Link href={HEAVY_PENALTIES_URL}>heavy penalties</Link>
);

const howToRenewExpiredlicenceLink = renderToString(
  <Link href={LATE_LICENCE_RENEWAL_URL}>how to renew an expired licence.</Link>
);

const eyeSightTestLink = renderToString(
  <Link href={EYESIGHT_TEST_URL}>eyesight test</Link>
);

const howDemeritPointsWorkLink = renderToString(
  <Link href={HOW_DEMERIT_POINTS_WORK_URL}>How demerit points work</Link>
);

const howMedicalRequirementsWorkLink = renderToString(
  <Link href={HOW_MEDICAL_REQUIREMENTS_WORK_URL}>
    How medical requirements work
  </Link>
);

const FitnessToDriveAssessmentProcessLink = renderToString(
  <Link href={FITNESS_TO_DRIVE_ASSESMENT_PROCESS_URL}>
    Fitness to Drive Assessment process
  </Link>
);

const SuspensionAndDisqualificationLink = renderToString(
  <Link href={SUSPENSION_DISQUALIFICATION_URL}>
    Suspension and disqualification
  </Link>
);

const lodgeYourAppealOnlineLink = renderToString(
  <Link href={LODGE_APPEAL_ONLINE_URL}>lodge your appeal online</Link>
);

const drivingRecordOnlineLink = renderToString(
  <Link href={DRIVING_RECORD_ONLINE_URL}>driving record online</Link>
);

export const cannotReplaceLicencePageHeading =
  'Sorry, we cannot replace your licence online';
export const youCannotReplaceLicencePageHeading =
  'You cannot replace your licence online';
export const sorryAboutThisPageHeading = "We're sorry about this...";
export const finishApplicationInPersonHeading =
  'Please finish your application in person';

export const cannotProceedOnlineTitle = 'You cannot proceed online';

export const yourNswTitle = 'Your NSW Driver Licence';
export const notFoundTitle = 'NSW Driver Licence not found';
export const yourDetailsNotFoundTitle = 'Your details were not found';
export const noLicenceFoundTitle = 'You do not have a driving licence';

export const weNeedToCheckDetailsTitle =
  'We need to check some details with you';
export const replacementLast12DaysTitle =
  "You've already replaced your licence online in the last 12 months";
export const replacementLast14DaysTitle =
  'A replacement licence has already been requested in the past 14 days';
export const changedAddressTitle =
  "You've changed your address online recently";

export const dualClassTitle =
  'Both of your NSW Driver Licence classes are suspended, cancelled, disqualified or expired';

export const dualClassTitleBothExpired =
  'Your NSW Driver Licence classes are expired';

export const dualClassTitleOneExpired =
  'One of your licence classes is expired';
export const dualClassTitleOneSuspended =
  'One of your licence classes is suspended';
export const dualClassTitleOneDisqualified =
  'One of your licence classes is disqualified';

export const dualClassTitleOneCancelled =
  'One of your licence classes is cancelled';

export const dualClassTitlePendingCancSuspension = (
  WarningType: string
): string => {
  return `One of your licence classes is active and the other is pending ${WarningType}`;
};

export const needHelpDescription = (
  <p>
    Need help? Call us on {serviceCenterPhoneNumber} or visit a{' '}
    {serviceCenterLink}.
  </p>
);

export const multipleDeclarationFoundDescription = (
  <p>
    You have multiple declarations. Please visit a {serviceCenterLink} to
    replace your licence.
    <br></br>
    <br></br>
    If you&apos;re entitled to a concession, please bring your concession card
    with you.
  </p>
);

export const singleClassExpiredCancelledDisqualifiedDesc = (
  <p>
    This is currently under appeal. For more information, please call us on{' '}
    {serviceCenterPhoneNumber} or visit a {serviceCenterLink}.
  </p>
);

export const legallyDriveDownloadContent = (
  <p>
    To legally drive, you{' '}
    <b>must print and carry your Interim Driver Licence </b>
    or download your {downloadDigitalDriverLicenceLink} via the Service NSW app.{' '}
  </p>
);

export const dualClassDescriptionOneExpired = (
  <p>
    Your new NSW Driver Licence card will only show your active class.{' '}
    <b>You must only drive or ride vehicles permitted by your active class.</b>
    <br />
    <br />
    You may be eligible to {renewLicenceLink} online or {licenceUpgradeLink} in
    order to continue driving or riding.
    <br />
    <br />
    {needHelpDescription}
  </p>
);
export const dualClassDescriptionOneSuspended = (
  <p>
    You will not be able to replace this licence class online. Your new NSW
    Driver Licence card will only show your active class.
    <b>You must only drive or ride vehicles permitted by your active class</b>
    <br />
    <br />
    For more information, refer to{' '}
    <a
      target='_blank'
      rel='noopener noreferrer'
      href={SUSPENSION_DISQUALIFICATION_URL}
    >
      suspension
    </a>
    .
    <br />
    <br />
    {needHelpDescription}
  </p>
);

export const dualClassDescriptionOneCancelled = (
  <p>
    You will not be able to replace this licence class online. Your new NSW
    Driver Licence card will only show your active class.
    <b>You must only drive or ride vehicles permitted by your active class</b>
    <br />
    <br />
    For more information, refer to{' '}
    <a
      target='_blank'
      rel='noopener noreferrer'
      href={SUSPENSION_DISQUALIFICATION_URL}
    >
      cancellation
    </a>
    .
    <br />
    <br />
    {needHelpDescription}
  </p>
);

export const dualClassDescriptionOneDisqualified = (
  <p>
    You will not be able to replace this licence class online. Your new NSW
    Driver Licence card will only show your active class.
    <b>You must only drive or ride vehicles permitted by your active class</b>
    <br />
    <br />
    For more information, refer to{' '}
    <a
      target='_blank'
      rel='noopener noreferrer'
      href={SUSPENSION_DISQUALIFICATION_URL}
    >
      disqualification
    </a>
    .
    <br />
    <br />
    {needHelpDescription}
  </p>
);

export const DualClassDescriptionErrorAndRefusalOrSanction = (
  errorStatus: string,
  warningStatus: string
): JSX.Element => {
  return (
    <p>
      You will not be able to replace the {errorStatus} class online. For your
      active class you will only receive an {printInterimDriverLicenceLink} and
      will not receive a new driver licence card. Your Interim Driver Licence is
      valid for two months from the issue date.
      <b>
        {' '}
        You must only drive or ride vehicles permitted by your active class.
      </b>
      <br />
      <br />
      Alternatively, you can download your {
        downloadDigitalDriverLicenceLink
      }{' '}
      via the Service NSW app.
      <br />
      <br />
      For more information, refer to{' '}
      <a
        target='_blank'
        rel='noopener noreferrer'
        href={SUSPENSION_DISQUALIFICATION_URL}
      >
        {warningStatus}
      </a>
      .
      <br />
      <br />
      {needHelpDescription}
    </p>
  );
};

export const DualClassDescriptionErrorAndPendingCanOrSusp = (
  errorStatus: string
): JSX.Element => {
  return (
    <p>
      You can still complete this transaction online but you{' '}
      <b>must not drive if your licence is suspended or cancelled.</b> Your{' '}
      {errorStatus} class will not appear on your replacement licence.
      <br />
      <br />
      For more information, refer to{' '}
      <a
        target='_blank'
        rel='noopener noreferrer'
        href={SUSPENSION_DISQUALIFICATION_URL}
      >
        {errorStatus}
      </a>
      .
      <br />
      <br />
      You may also want to lodge an {appealOnlineLink}.
      <br />
      <br />
      {needHelpDescription}
    </p>
  );
};

export const DualClassHasActiveAndPendingSuspOrCanc = (
  <p>
    You can still complete this transaction online but you
    <b> must not drive if your licence is suspended or cancelled.</b>
    <br />
    <br />
    You may also want to lodge an {appealOnlineLink}.
    <br />
    <br />
    {needHelpDescription}
  </p>
);

export const DualClassHasActiveAndSanctionOrRefusalPresent = (
  <p>
    Only one of your licence classes will appear on your new licence.
    <br />
    <br />
    {needHelpDescription}
  </p>
);

export const dualClassHasActiveAndAppealedSanctionOrCancelPresentDescription = (
  errorStatus: string
): JSX.Element => {
  return (
    <p>
      This is currently under appeal. You will not be able to replace this
      licence class online. Your new NSW Driver Licence card will only show your
      active class.{' '}
      <b>
        You must only drive or ride vehicles permitted by your active class.
      </b>
      <br />
      <br />
      For more information, refer to{' '}
      <a
        target='_blank'
        rel='noopener noreferrer'
        href={SUSPENSION_DISQUALIFICATION_URL}
      >
        {errorStatus}
      </a>
      .
      <br />
      <br />
      {needHelpDescription}
    </p>
  );
};

export const forMoreInformationDescription = (
  <span>
    For more information, please call us on {serviceCenterPhoneNumber} or visit
    a {serviceCenterLink}.
  </span>
);

export const needHelpDescriptionWithPlease = (
  <span>
    Need help? Please call us on {serviceCenterPhoneNumber} or visit a{' '}
    {serviceCenterLink}.
  </span>
);

export const callUsOrVisitDescription = (
  <span>
    Please call us on {serviceCenterPhoneNumber} or visit a {serviceCenterLink}{' '}
    {"to check if you're eligible for a replacement."}
  </span>
);

export const callUsOrVisitWithApology = (
  <span>
    Please call us on {serviceCenterPhoneNumber} or visit a {serviceCenterLink}
    {'. We apologise for any inconvenience.'}
  </span>
);

export const dualClassBothExpiredDescription = (
  <>
    <p>
      You may be eligible to {renewLicenceLink} online. Alternatively, if you
      require a card for identification you can apply for a{' '}
      <a href={NSW_PHOTO_CARD_URL} target='_blank' rel='noopener noreferrer'>
        NSW Photo Card
      </a>{' '}
      instead.
    </p>{' '}
    <br />
    <p>{needHelpDescriptionWithPlease}</p>
  </>
);

export const dualClassDescription = (
  <>
    <p>
      If you need your licence card for identification purposes, you may want to
      apply for a{' '}
      <a href={NSW_PHOTO_CARD_URL} target='_blank' rel='noopener noreferrer'>
        NSW Photo Card
      </a>{' '}
      instead.
    </p>{' '}
    <br />
    <p>{needHelpDescriptionWithPlease}</p>
  </>
);

export const mayEligibleRenewOnlineDescription = (
  <span>
    You may be eligible to {renewLicenceLink} online in order to continue
    driving or riding. {needHelpDescription}
  </span>
);

export const mayEligibleRenewOnlineDueExpiryDescription = (
  <>
    <p>
      You may be eligible for an online {licenceRenewalLink} or{' '}
      {licenceUpgradeLink}. This will save you time by only needing to complete
      a licence renewal transaction.
    </p>
    <br />
    <p>{needHelpDescription}</p>
  </>
);

export const ifYouNeedToReplaceWithinTwelveMonthTwiceDescription = (
  <span>
    If you need to replace your NSW Driver Licence again, please visit a{' '}
    {serviceCenterLink}.
  </span>
);
export const ifYouNeedToReplaceIfHasRecentlyUpdatedAddressDescription = (
  <span>
    If you need to replace your NSW Driver Licence, please visit a{' '}
    {serviceCenterLink}.
  </span>
);

export const activePhotoCardFoundDescription = (
  <span>
    {
      "A NSW Photo Card may be used as photo identification if you don't have a NSW"
    }
    Driver Licence. You can access this via the {SNSWAppLink}. If you need a
    replacement card, please visit a {serviceCenterLink}.
  </span>
);

export const mpsCardFoundDescription = (
  <span>
    It looks like you hold a Mobility Parking Scheme (MPS) permit, however there
    is no medical condition recorded on your NSW Driver Licence. Please call us
    on {serviceCenterPhoneNumber} or visit a {serviceCenterLink}.
  </span>
);

export const licenceOrderedInLast14DaysDescription = (
  <span>
    <p>
      Your NSW Driver Licence can take up to two weeks to arrive. While waiting
      for your licence you can:
    </p>
    <Spacer mt={1.5} mb={1.5}>
      <ul>
        <li>
          Print an {printInterimDriverLicenceLink} (your Driver Licence
          receipt), or
        </li>
        <li>
          Download your NSW {downloadDigitalDriverLicenceLink} via the Service
          NSW App.
        </li>
      </ul>
    </Spacer>
    {needHelpDescriptionWithPlease}
  </span>
);

export const visitServiceCenterForPhoto = (
  <span>
    Please visit a {serviceCenterLink} to have your photo taken for your NSW
    Driver Licence.
  </span>
);

export const consessionCanNotValidateTitle =
  'Your concession details cannot be validated right now';

export const pensionerValidationDeferredDescription = (
  <p>
    You can continue your transaction and any applicable concessions will apply.
    Transport for NSW will contact you if they can&apos;t validate your
    concession details later.
  </p>
);

export const notEligibleForPensionConssesionTitle =
  'Your concession cannot be applied';

export const unableToverifyConssesionDescription = (
  <>
    <p>
      We are unable to verify your concession details. You can proceed without a
      concession and full fees will apply, or try again later.
    </p>
    <br />
    <p>{needHelpDescription}</p>
  </>
);

export const notEligibleDescription = (
  <>
    It looks like you are not eligible to replace your NSW Driver Licence
    online. {forMoreInformationDescription}
  </>
);

const UnderstandingLicenceStatusTitle = 'Understanding your licence status';
const UnderstandingLicenceStatusDescription = `
      <p>
      Transport for NSW may refuse, suspend, cancel or disqualify your licence if
      you have exceeded your demerit point threshold, committed a serious speeding
      offence or have not complied with medical review requirements. There may be
      other reasons for a licence disqualification or cancellation.
      </p>
      <p>For further information:</p>
      <ul>
        <li>${howDemeritPointsWorkLink}</li>
        <li>${SuspensionAndDisqualificationLink}</li>
        <li>${howMedicalRequirementsWorkLink}</li>
        <li>${FitnessToDriveAssessmentProcessLink}</li>
      </ul>
    `;

const requestDrivingRecordTitle = 'You can request a driving record';
const requestDrivingRecordDescription = `
      <p>
        A driving record is a history of offences recorded against your licence,
        and you're able to buy a copy of your own record if you need it.
      </p>
      <p>You can order 2 types of ${drivingRecordOnlineLink}:</p>
      <ul>
        <li>
          an uncertified driving record that <b>cannot</b> be used for legal proceedings,
          or
        </li>
        <li>
          a certified driving record that can be used for legal proceedings.
        </li>
      </ul>
  `;

const lodgeAppealOnlineTitle = 'You can lodge an appeal online';
const lodgeAppealOnlineDescription = `
      <p>
        You have the right to appeal an immediate licence suspension in court.
      </p>
  
      <p>
        After receiving the Notice of Suspension, you have 28 days to ${lodgeYourAppealOnlineLink} or in person at a NSW local court. You will need to pay a
        fee to the court to lodge your application.
      </p>
      <p>
        Unless the court orders otherwise you must not drive until your appeal is
        heard. If your appeal is denied you will not be allowed to drive until
        your suspension period ends.
      </p>
  `;

const lateLicenceRenewalInfoDescription = `
      <p>
        Once your licence expires, you must not drive until you have renewed it.
      </p>
      <p>
        It's your responsibility to renew your licence on time – even if you
        didn't receive the reminder notice. There are
        ${heavyPenaltiesLink}
        for driving with an expired licence.
      </p>
      <p>
        For more information, refer to
        ${howToRenewExpiredlicenceLink}
      </p>
  `;

export const undergoAnEyesightTestInfoDescription = `
      <p>
        You’ll be notified by mail if you need an
        ${eyeSightTestLink}.
         If you’re notified, you’ll have up to 8 weeks to visit a service centre,
        optometrist or ophthalmologist for your eye exam.
      </p>
      <p>
        The eyesight test is also done onsite at a
        ${serviceCenterLinkForExpired}
       when you renew or apply for a licence.
      </p>
  `;

export const licencePendingCancellationTitle = `Your licence is pending a cancellation`;
export const licencePendingSuspensionTitle = `Your licence is pending a suspension`;

export const licencePendingCancelSuspension = (
  <>
    <p>
      You can still complete this transaction online but you must not drive if
      your licence is suspended or cancelled.
    </p>
    <Spacer mt={1.5} mb={1.5}>
      <p>For further assistance, you may lodge an {appealOnlineLink}.</p>
    </Spacer>
    <p>{needHelpDescription}</p>
  </>
);

export const licencePendingCancOrSuspendedDualClass = (
  <>
    <p>
      You can still complete this transaction online but you must not drive if
      your licence is suspended or cancelled.
    </p>
    <Spacer mt={1.5} mb={1.5}>
      <p>You may also want to lodge an {appealOnlineLink}.</p>
    </Spacer>
    <p>{needHelpDescription}</p>
  </>
);

export const restrictedLicenceTitle = 'There is a restriction on your licence';
export const restrictedLicenceDescription = (
  <>
    <p>
      You can still complete this transaction and receive an{' '}
      {printInterimDriverLicenceLink} that is valid for two months from date of
      issue or until your licence expires (whichever comes first).
      Alternatively, you can download your {downloadDigitalDriverLicenceLink}{' '}
      via the Service NSW app.
    </p>
    <br />
    {needHelpDescription}
  </>
);

export const systemUnvailableTitle = 'Our system is temporarily unavailable';
export const systemUnvailableDescription = (
  <span>
    You can try again later, call us on {serviceCenterPhoneNumber} or visit a{' '}
    {serviceCenterLink}. We apologise for any inconvenience.
  </span>
);

export const infoForDisqualifiedCancelledSuspended: IInfo[] = [
  {
    title: UnderstandingLicenceStatusTitle,
    description: UnderstandingLicenceStatusDescription,
  },
  {
    title: requestDrivingRecordTitle,
    description: requestDrivingRecordDescription,
  },
  {
    title: lodgeAppealOnlineTitle,
    description: lodgeAppealOnlineDescription,
  },
];

export const infoForCannotProceedOnline: IInfo[] = [
  {
    title: UnderstandingLicenceStatusTitle,
    description: UnderstandingLicenceStatusDescription,
  },
  {
    title: requestDrivingRecordTitle,
    description: requestDrivingRecordDescription,
  },
  {
    title: lodgeAppealOnlineTitle,
    description: lodgeAppealOnlineDescription,
  },
];

export const cannotProceedOnlineDescription = (
  <>
    <p>
      You have answered ‘yes’ to one or more of the declaration questions.
      Please visit a {serviceCenterLink} to replace your licence.
    </p>
    <br />
    <p>
      If you’re entitled to a concession, please bring your concession card with
      you.
    </p>
  </>
);

export const visitServiceCentreDescription = (
  <>
    It looks like your NSW Driver Licence card has been returned. If you need a
    replacement, please visit a {serviceCenterLink}.
  </>
);

export const infoForExpired: IInfo[] = [
  {
    title: 'Late licence renewal',
    description: lateLicenceRenewalInfoDescription,
  },
  {
    title: 'You may need to undergo an eyesight test',
    description: undergoAnEyesightTestInfoDescription,
  },
];

export const infoForDueExpiry: IInfo[] = [
  {
    title: 'Late licence renewal',
    description: lateLicenceRenewalInfoDescription,
  },
  {
    title: 'You may need to undergo an eyesight test',
    description: undergoAnEyesightTestInfoDescription,
  },
];

export const declarationAlreadyExistTitle =
  'Your declaration has already been submitted';
export const declarationAlreadyExistDescription = (
  <p>
    You have previously submitted these answers and cannot change them online.
    If your declaration is incorrect please visit a {serviceCenterLink}.
  </p>
);

export const sendEmailErrorNotification = (
  <p>
    We were unable to send your email, please try again. Need help? Call us on{' '}
    {serviceCenterPhoneNumber} or visit a {serviceCenterLink}.
  </p>
);
