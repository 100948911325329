import React from 'react';
import { serviceCenterPhoneNumber } from 'utils/shared/shared';
import {
  legallyDriveDownloadContent,
  serviceCenterLink,
} from 'utils/helpers/ErrorHandlers/helpers/errorContentDetails';

export const ActiveRefusalSanctionModalContent = (
  <>
    <p>
      You have restrictions on your licence. You may continue to payment and
      download your paper Interim Driver Licence <b>only.</b> It is valid for
      two months.
    </p>
    {legallyDriveDownloadContent}
    <p>
      If you need help, call us on {serviceCenterPhoneNumber} or visit a{' '}
      {serviceCenterLink}.
    </p>{' '}
  </>
);

export const ActiveRefusalSanctionModalContentForPensioner = (
  <>
    <p>
      You have restrictions on your licence. You may continue and
      download your paper Interim Driver Licence <b>only.</b> It is valid for
      two months.
    </p>
    {legallyDriveDownloadContent}
    <p>
      If you need help, call us on {serviceCenterPhoneNumber} or visit a{' '}
      {serviceCenterLink}.
    </p>{' '}
  </>
);
