import React from 'react';

const FrontOfPhotoCard = ({ width = 48, height = 35 }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    fill='none'
    viewBox='0 0 230 115'
  >
    <path
      fill='#AAC5CB'
      stroke='#979797'
      d='M70.42 1h148.074c6.103 0 11.006 4.879 11.006 10.952v91.598c0 6.074-4.903 10.952-11.006 10.952H70.421c-6.104 0-11.006-4.878-11.006-10.952V11.952C59.415 5.879 64.317 1 70.421 1z'
    ></path>
    <path
      fill='#fff'
      fillRule='evenodd'
      d='M229.5 22.904H59.415V103.551c0 6.073 4.902 10.952 11.006 10.952h148.073c6.103 0 11.006-4.879 11.006-10.952V22.904z'
      clipRule='evenodd'
    ></path>
    <path
      fill='#8D909B'
      fillRule='evenodd'
      d='M118.648 104.301c0 2-1.199 3.7-3.098 3.7-1.899 0-3.099-1.7-3.099-3.7s1.2-3.7 3.099-3.7c1.899 0 3.098 1.6 3.098 3.7zm-4.897-.1c0 1.4.699 2.6 1.799 2.6 1.099 0 1.799-1.1 1.799-2.6 0-1.4-.7-2.6-1.799-2.6-1.1 0-1.799 1.2-1.799 2.6z'
      clipRule='evenodd'
    ></path>
    <path
      fill='#8D909B'
      d='M120.648 107.901h1.199v-7.3h-.8l-1.999.6.3 1.1 1.3-.4v6zM122.147 109.2h1.099l4.598-9.5h-.999l-4.698 9.5z'
    ></path>
    <path
      fill='#8D909B'
      fillRule='evenodd'
      d='M133.742 104.301c0 2-1.2 3.7-3.099 3.7-1.799 0-3.098-1.7-3.098-3.7s1.199-3.7 3.098-3.7c1.899 0 3.099 1.6 3.099 3.7zm-4.798-.1c0 1.4.7 2.6 1.799 2.6 1.1 0 1.799-1.1 1.799-2.6 0-1.4-.699-2.6-1.799-2.6-1.099 0-1.799 1.2-1.799 2.6z'
      clipRule='evenodd'
    ></path>
    <path
      fill='#8D909B'
      d='M135.741 107.901h1.299v-7.3h-.899l-1.899.6.2 1.1 1.299-.4v6zM137.34 109.2h1l4.698-9.5h-1.1l-4.598 9.5zM142.738 107.9h5.198v-1.1h-3.399l1.499-1.2c1.3-1 1.9-1.7 1.9-2.8 0-1.3-1-2.1-2.399-2.1-1.3 0-1.999.5-2.599 1.5l.899.7c.5-.7 1-1.1 1.7-1.1.699 0 1.199.4 1.199 1.1 0 .6-.4 1.1-1.299 1.9l-2.499 2.1v1h-.2z'
    ></path>
    <path
      fill='#8D909B'
      fillRule='evenodd'
      d='M154.933 104.301c0 2-1.2 3.7-3.099 3.7-1.899 0-3.099-1.7-3.099-3.7s1.2-3.7 3.099-3.7c1.899 0 3.099 1.6 3.099 3.7zm-4.898-.1c0 1.4.699 2.6 1.799 2.6 1.099 0 1.799-1.1 1.799-2.6 0-1.4-.7-2.6-1.799-2.6-1.1 0-1.799 1.2-1.799 2.6zM161.93 104.301c0 2-1.2 3.7-3.099 3.7-1.899 0-3.099-1.7-3.099-3.7s1.2-3.7 3.099-3.7c1.899 0 3.099 1.6 3.099 3.7zm-4.898-.1c0 1.4.699 2.6 1.799 2.6 1.099 0 1.799-1.1 1.799-2.6 0-1.4-.7-2.6-1.799-2.6-1.1 0-1.799 1.2-1.799 2.6zM168.927 104.301c0 2-1.2 3.7-3.099 3.7-1.899 0-3.099-1.7-3.099-3.7s1.2-3.7 3.099-3.7c1.899 0 3.099 1.6 3.099 3.7zm-4.898-.1c0 1.4.699 2.6 1.799 2.6 1.099 0 1.799-1.1 1.799-2.6 0-1.4-.7-2.6-1.799-2.6-1.1 0-1.799 1.2-1.799 2.6z'
      clipRule='evenodd'
    ></path>
    <path
      fill='#979797'
      d='M59.415 22.904v-.498h-.5v.498h.5zm170.085 0h.5v-.498h-.5v.498zm-170.085.498H229.5v-.996H59.415v.996zm.5 11.45V22.904h-1v11.948h1zm0 34.847V34.852h-1v34.847h1zm0 33.851V69.699h-1v33.851h1zm10.506 10.455c-5.803 0-10.506-4.68-10.506-10.455h-1c0 6.372 5.102 11.45 11.506 11.45v-.995zm148.073 0H70.421V115h148.073v-.995zm10.505-10.455c0 5.775-4.702 10.455-10.505 10.455V115c6.403 0 11.506-5.078 11.506-11.45h-1.001zm0-33.851v33.851H230V69.699h-1.001zm0-34.847v34.847H230V34.852h-1.001zm0-11.948v11.948H230V22.904h-1.001z'
    ></path>
    <path
      fill='#242934'
      d='M70.92 74.876h1.502v-8.463h-1l-2.302.697.3 1.195 1.5-.399v6.97zM74.122 74.876h6.103v-1.294h-4.001l1.7-1.394c1.501-1.195 2.201-1.892 2.201-3.286 0-1.493-1.1-2.489-2.801-2.489-1.5 0-2.301.597-3.102 1.792l1.101.797c.6-.797 1.2-1.295 1.901-1.295.7 0 1.4.498 1.4 1.295 0 .796-.4 1.294-1.6 2.29l-2.902 2.489v1.095zM84.628 74.975c1.8 0 2.901-1.095 2.901-2.688 0-1.493-1.2-2.19-2.501-2.39l2.301-2.389v-1.095h-5.603v1.294h3.602l-2.101 2.49.2.896h.7c1.201 0 1.901.497 1.901 1.294 0 .796-.6 1.294-1.5 1.294s-1.601-.398-2.202-1.095l-1 .996c.8.796 1.901 1.394 3.302 1.394z'
    ></path>
    <path
      fill='#242934'
      fillRule='evenodd'
      d='M94.733 74.876h-1.401v-1.892H89.13l-.3-1.095 4.702-5.476h1.3v5.377h1.201v1.194h-1.3v1.892zm-1.501-6.372l-2.802 3.285h2.802v-3.285z'
      clipRule='evenodd'
    ></path>
    <path
      fill='#242934'
      d='M100.135 74.976c1.901 0 3.102-1.096 3.102-2.788 0-1.792-1.301-2.589-2.901-2.589-.6 0-.9.1-1.301.2l.1-1.992h3.802v-1.294h-5.003l-.2 4.082.9.597c.4-.199.901-.398 1.501-.398 1.001 0 1.701.498 1.701 1.394 0 .896-.7 1.493-1.701 1.493-.8 0-1.5-.398-2.2-.995l-.901 1.095c.8.697 1.8 1.195 3.101 1.195z'
    ></path>
    <path
      fill='#242934'
      fillRule='evenodd'
      d='M111.141 72.188c0 1.692-1.401 2.887-3.102 2.887-1 0-1.801-.299-2.301-.896-.6-.597-1-1.493-1-3.186 0-2.589 1.2-4.58 3.601-4.58 1.101 0 1.801.299 2.602.896l-.801 1.095c-.6-.498-1.1-.697-1.801-.697-1.5 0-2.001 1.494-2.001 2.689.501-.498 1.101-.896 2.001-.896 1.501 0 2.802.995 2.802 2.688zm-4.903 0c0 .896.701 1.493 1.701 1.493 1.001 0 1.701-.597 1.701-1.493 0-.797-.7-1.494-1.701-1.494-1 0-1.701.697-1.701 1.494z'
      clipRule='evenodd'
    ></path>
    <path
      fill='#242934'
      d='M112.942 74.876h1.701l3.801-7.268v-1.095h-5.902v1.294h4.202l-3.802 7.069z'
    ></path>
    <path
      fill='#242934'
      fillRule='evenodd'
      d='M122.647 74.975c1.8 0 3.201-.895 3.201-2.389 0-.996-.6-1.593-1.501-1.991.701-.398 1.201-.896 1.201-1.892 0-1.294-1.201-2.29-2.901-2.29-1.701 0-2.902.996-2.902 2.29 0 .896.5 1.493 1.201 1.892-.901.398-1.501.995-1.501 1.991 0 1.394 1.301 2.39 3.202 2.39zm0-4.978c-.901 0-1.501-.497-1.501-1.194 0-.598.6-1.195 1.501-1.195.9 0 1.5.498 1.5 1.195 0 .697-.7 1.194-1.5 1.194zm-1.801 2.49c0 .696.6 1.294 1.801 1.294 1.2 0 1.8-.598 1.8-1.294 0-.797-.8-1.295-1.8-1.295-1.001 0-1.801.498-1.801 1.295z'
      clipRule='evenodd'
    ></path>
    <path
      fill='#242934'
      d='M91.531 15.934h1.5V9.96l4.603 5.974h1.2V7.57h-1.5v5.775L92.832 7.57h-1.4v8.364h.1z'
    ></path>
    <path
      fill='#242934'
      fillRule='evenodd'
      d='M106.338 14.939c-.6.697-1.4 1.195-2.601 1.195-1.801 0-3.301-1.295-3.301-3.286 0-1.792 1.3-3.286 3.101-3.286 2.001 0 3.002 1.594 3.002 3.386v.398h-4.703c.2.995.901 1.593 1.801 1.593.7 0 1.201-.299 1.701-.797l1 .797zm-2.701-4.281c-.9 0-1.501.697-1.601 1.692h3.302c-.2-.896-.8-1.692-1.701-1.692z'
      clipRule='evenodd'
    ></path>
    <path
      fill='#242934'
      d='M109.34 15.934h1.301l1.4-4.28 1.401 4.28h1.301l2.101-6.372h-1.501l-1.301 4.282-1.4-4.381h-1.201l-1.401 4.38-1.3-4.181h-1.501l2.101 6.272zM124.848 16.034c1.801 0 3.001-.896 3.001-2.489 0-1.394-.9-1.991-2.701-2.489-1.601-.398-2.001-.697-2.001-1.294 0-.598.5-.996 1.401-.996.8 0 1.5.299 2.301.896l.8-1.095c-.9-.697-1.901-1.095-3.101-1.095-1.701 0-2.902.995-2.902 2.39 0 1.493 1.001 2.09 2.802 2.488 1.6.399 1.9.697 1.9 1.295 0 .597-.6 1.095-1.5 1.095-1.001 0-1.801-.399-2.702-1.095l-.9 1.095c1.1.896 2.301 1.294 3.602 1.294z'
    ></path>
    <path
      fill='#242934'
      fillRule='evenodd'
      d='M135.853 12.748c0 1.793-1.401 3.286-3.402 3.286-1.9 0-3.401-1.493-3.401-3.285 0-1.793 1.4-3.286 3.401-3.286s3.402 1.493 3.402 3.286zm-5.302 0c0 1.096.8 1.992 1.9 1.992 1.201 0 1.901-.896 1.901-1.992 0-1.095-.8-1.99-1.901-1.99-1.2 0-1.9.895-1.9 1.99z'
      clipRule='evenodd'
    ></path>
    <path
      fill='#242934'
      d='M139.455 16.034c1 0 1.601-.498 2.001-1.095v.996h1.501V9.662h-1.501v3.584c0 .996-.6 1.593-1.401 1.593-.9 0-1.4-.597-1.4-1.493V9.662h-1.501v3.983c.1 1.493.9 2.39 2.301 2.39zM146.859 16.034c.6 0 .9-.1 1.3-.298V14.54c-.3.1-.6.199-.9.199-.5 0-.801-.2-.801-.796v-3.087h1.701V9.662h-1.701V7.87h-1.5v1.693h-.801v1.194h.801v3.286c.1 1.494.9 1.991 1.901 1.991zM149.66 15.934h1.501V12.35c0-.995.6-1.593 1.4-1.593.901 0 1.401.598 1.401 1.494v3.584h1.501v-3.983c0-1.493-.8-2.39-2.301-2.39-1.001 0-1.601.499-2.001 1.096V7.173h-1.501v8.761zM163.167 16.034h1.3l2.101-5.974 2.101 5.974h1.301l2.902-8.463h-1.501l-2.001 6.173-2.101-6.272h-1.201l-2.101 6.272-2.001-6.173h-1.601l2.802 8.463z'
    ></path>
    <path
      fill='#242934'
      fillRule='evenodd'
      d='M177.174 15.138c-.4.498-1.101.896-2.101.896-1.201 0-2.201-.697-2.201-1.991 0-1.394 1.1-1.991 2.601-1.991.7 0 1.2.1 1.701.298v-.199c0-.896-.601-1.394-1.601-1.394-.7 0-1.201.2-1.801.399l-.4-1.096a5.71 5.71 0 012.401-.498c.9 0 1.701.3 2.101.697.5.498.7 1.096.7 1.992v3.783h-1.4v-.896zm-2.902-1.095c0 .597.501.996 1.201.996 1 0 1.701-.598 1.701-1.394v-.399c-.4-.1-.901-.298-1.401-.298-.9 0-1.501.398-1.501 1.095z'
      clipRule='evenodd'
    ></path>
    <path fill='#242934' d='M180.475 15.934h1.501V7.173h-1.501v8.761z'></path>
    <path
      fill='#242934'
      fillRule='evenodd'
      d='M189.28 14.939c-.601.697-1.401 1.195-2.602 1.195-1.8 0-3.301-1.295-3.301-3.286 0-1.792 1.3-3.286 3.101-3.286 2.001 0 3.002 1.594 3.002 3.386v.398h-4.702c.2.995.9 1.593 1.8 1.593.701 0 1.201-.299 1.701-.797l1.001.797zm-2.802-4.281c-.9 0-1.5.697-1.6 1.692h3.301c-.2-.896-.7-1.692-1.701-1.692z'
      clipRule='evenodd'
    ></path>
    <path
      fill='#242934'
      d='M193.182 16.034c1.3 0 2.401-.697 2.401-1.991 0-1.095-1.001-1.593-2.001-1.792-.7-.2-1.401-.399-1.401-.896 0-.399.3-.598.901-.598.5 0 1.2.2 1.801.598l.6-.996c-.701-.398-1.501-.697-2.301-.697-1.301 0-2.301.697-2.301 1.892 0 1.195 1 1.593 2.001 1.891.7.2 1.4.399 1.4.897 0 .398-.4.697-1 .697-.601 0-1.401-.3-2.101-.797l-.601.996c.701.498 1.701.796 2.602.796z'
    ></path>
    <path
      fill='#8D909B'
      d='M163.367 40.372h1.1V34.2h-.8l-1.701.498.2.896 1.101-.299v5.078h.1zM167.669 40.372h4.502v-.996h-2.901l1.3-1.095c1.101-.896 1.601-1.394 1.601-2.39 0-1.094-.8-1.891-2.001-1.891-1.1 0-1.701.498-2.301 1.294l.801.598c.5-.598.9-.896 1.4-.896.601 0 1.001.398 1.001.995 0 .598-.3.896-1.201 1.693l-2.101 1.792v.896h-.1zM175.073 40.472c1.3 0 2.201-.797 2.201-1.992 0-1.095-.901-1.593-1.801-1.692l1.701-1.792v-.797h-4.102v.896h2.701l-1.701 1.792.2.697h.501c.9 0 1.4.3 1.4.996 0 .597-.5.896-1.1.896-.701 0-1.201-.299-1.601-.796l-.801.697c.501.597 1.301 1.095 2.402 1.095z'
    ></path>
    <path
      fill='#8D909B'
      fillRule='evenodd'
      d='M182.076 40.372h-1v-1.394h-3.102l-.2-.796 3.402-4.082h.9v3.982h.901v.896h-.901v1.394zm-1-4.68l-2.001 2.49h2.001v-2.49z'
      clipRule='evenodd'
    ></path>
    <path
      fill='#8D909B'
      d='M188.079 40.472c1.401 0 2.301-.797 2.301-2.091s-1-1.892-2.201-1.892c-.4 0-.7.1-1 .2l.1-1.494h2.801v-.996h-3.702l-.2 2.987.701.398c.3-.199.7-.298 1.1-.298.8 0 1.301.398 1.301.995 0 .598-.501 1.096-1.201 1.096-.6 0-1.1-.3-1.601-.697l-.7.796c.6.598 1.301.996 2.301.996z'
    ></path>
    <path
      fill='#8D909B'
      fillRule='evenodd'
      d='M195.783 38.38c0 1.195-1.001 2.092-2.301 2.092-.801 0-1.301-.2-1.701-.697-.5-.498-.8-1.096-.8-2.39 0-1.892.9-3.385 2.701-3.385.8 0 1.401.299 1.901.697l-.6.796c-.401-.298-.801-.497-1.301-.497-1.101 0-1.501 1.095-1.501 1.99.4-.397.801-.596 1.501-.596 1.1 0 2.101.697 2.101 1.99zm-3.602 0c0 .598.5 1.096 1.301 1.096.8 0 1.2-.498 1.2-1.095 0-.598-.5-1.095-1.3-1.095-.701 0-1.201.497-1.201 1.095z'
      clipRule='evenodd'
    ></path>
    <path
      fill='#8D909B'
      d='M196.683 40.372h1.201l2.801-5.376v-.797h-4.402v.996h3.102l-2.702 5.177z'
    ></path>
    <path
      fill='#8D909B'
      fillRule='evenodd'
      d='M205.888 40.472c1.401 0 2.401-.697 2.401-1.793 0-.696-.4-1.194-1.1-1.493.5-.299.9-.697.9-1.394 0-.995-.9-1.692-2.201-1.692-1.301 0-2.201.697-2.201 1.692 0 .697.4 1.095.9 1.394-.7.299-1.1.797-1.1 1.493 0 1.096 1 1.793 2.401 1.793zm0-3.684c-.6 0-1.101-.398-1.101-.896s.401-.896 1.101-.896 1.101.398 1.101.896-.501.896-1.101.896zm-1.301 1.792c0 .498.501.896 1.301.896.8 0 1.301-.398 1.301-.896 0-.597-.601-.896-1.301-.896s-1.301.398-1.301.896zM209.49 38.879c.5.398.9.597 1.4.597 1.101 0 1.501-1.095 1.501-1.991-.3.398-.8.697-1.501.697-1.3 0-2.201-.797-2.201-1.992 0-1.194.901-2.09 2.302-2.09.8 0 1.3.199 1.7.697.501.497.701 1.095.701 2.39 0 1.99-1.001 3.384-2.702 3.384-.9 0-1.5-.298-2.001-.697l.801-.995zm.4-2.689c0 .598.5 1.096 1.301 1.096.8 0 1.3-.498 1.3-1.096 0-.597-.5-1.095-1.3-1.095-.801-.1-1.301.398-1.301 1.095zM219.595 37.286c0 1.792-1.101 3.186-2.702 3.186-1.6 0-2.701-1.394-2.701-3.186 0-1.793 1.101-3.186 2.701-3.186 1.701 0 2.702 1.393 2.702 3.186zm-4.202 0c0 1.194.6 2.19 1.601 2.19 1 0 1.6-.996 1.6-2.19 0-1.195-.6-2.19-1.6-2.19-1.001 0-1.601.895-1.601 2.19z'
      clipRule='evenodd'
    ></path>
    <path
      fill='#EFEFEF'
      d='M219.495 45.804h-48.024v49.781h48.024V45.804z'
    ></path>
    <path
      fill='#8D909B'
      fillRule='evenodd'
      d='M199.385 71.491a8.999 8.999 0 005.402-8.264c0-4.978-4.002-8.96-9.004-8.96-5.003 0-9.005 3.982-9.005 8.96 0 3.784 2.402 7.07 5.803 8.364-7.904 1.692-13.806 8.662-13.806 17.025v.498h35.017v-.498c0-8.563-6.203-15.632-14.407-17.125z'
      clipRule='evenodd'
    ></path>
    <path
      fill='#242934'
      d='M69.32 40.127l1-.995c.5.597 1.001.995 1.701.995.8 0 1.301-.497 1.301-1.692v-5.476h1.5v5.476c0 .996-.3 1.692-.8 2.19s-1.2.697-2 .697c-1.301.2-2.102-.398-2.702-1.195zM76.323 39.531c0-1.394 1.101-2.09 2.602-2.09.7 0 1.2.099 1.7.298v-.2c0-.895-.6-1.393-1.6-1.393-.7 0-1.2.2-1.801.398l-.4-1.095a5.71 5.71 0 012.401-.498c.9 0 1.7.299 2.101.697.5.498.7 1.095.7 1.991v3.784h-1.4v-.797c-.4.498-1.1.896-2.101.896-1.1 0-2.202-.697-2.202-1.99zm4.303-.398v-.398c-.4-.1-.9-.3-1.401-.3-.9 0-1.5.4-1.5 1.096 0 .598.5.996 1.2.996 1-.1 1.7-.598 1.7-1.394zM87.93 35.05h1.5l-2.501 6.472c-.5 1.295-1.1 1.793-2.101 1.793-.6 0-1.1-.1-1.5-.399l.5-1.095c.3.1.6.2.9.2.4 0 .7-.2.9-.698l-2.601-6.272h1.6l1.802 4.68 1.5-4.68zM94.132 37.241c0-2.39 1.801-4.38 4.303-4.38 1.5 0 2.501.497 3.301 1.294l-.9 1.095c-.701-.597-1.4-.996-2.401-.996-1.601 0-2.802 1.295-2.802 2.987 0 1.593 1.2 2.987 2.802 2.987 1 0 1.7-.398 2.401-1.095l.9.995c-.9.897-1.8 1.494-3.401 1.494-2.402-.1-4.203-1.991-4.203-4.38zM103.437 33.06h1.501v8.363h-1.501V33.06zM109.24 34.353h-2.701v-1.394h6.803v1.394h-2.601v6.97h-1.501v-6.97zM115.043 33.06h1.501v8.363h-1.501V33.06zM118.445 40.328l5.002-5.974h-4.802V33.06h6.703v1.095l-5.003 5.974h5.003v1.294h-6.903v-1.095zM127.149 33.06h6.203v1.294h-4.702v2.19h4.202v1.295h-4.202v2.29h4.802v1.294h-6.303V33.06zM135.153 33.06h1.401l4.502 5.774V33.06h1.501v8.363h-1.201l-4.602-5.974v5.974h-1.501V33.06h-.1z'
    ></path>
    <path
      fill='#B81237'
      fillRule='evenodd'
      d='M66.371 35.2l-16.593 8.3V26.9l16.593 8.3zM66.371 68.4l-16.593 8.3V60.1l16.593 8.3z'
      clipRule='evenodd'
    ></path>
    <path
      fill='#242934'
      fillRule='evenodd'
      d='M3.598 57.2H.2v8.6h1.5V63h1.699c1.899 0 3.398-1 3.498-2.9 0-1.8-1.3-2.9-3.299-2.9zm1.6 3c0 .9-.7 1.5-1.8 1.5v.1H1.7v-3.1h1.7c1.1 0 1.799.5 1.799 1.5z'
      clipRule='evenodd'
    ></path>
    <path
      fill='#242934'
      d='M7.997 61.5c0-2.5 1.799-4.4 4.398-4.4 1.599 0 2.498.5 3.298 1.3l-1 1.1c-.7-.6-1.399-1.1-2.398-1.1-1.6 0-2.8 1.3-2.8 3s1.2 3 2.8 3c1.1 0 1.699-.4 2.499-1.1l1 1c-.9.9-1.9 1.5-3.5 1.5-2.498.1-4.297-1.8-4.297-4.3zM0 70.6h1.5v1c.4-.6.999-1.1 1.998-1.1 1.5 0 2.3 1 2.3 2.5v4.1h-1.5v-3.7c0-1-.5-1.6-1.4-1.6-.899 0-1.499.6-1.499 1.6V77H0v-6.4zM7.497 74.7v-4.1h1.5v3.7c0 1 .499 1.6 1.399 1.6s1.499-.6 1.499-1.6v-3.6h1.5v6.5h-1.5v-1c-.4.6-1 1.1-2 1.1-1.499-.1-2.398-1.1-2.398-2.6zM15.193 70.6h1.5v1c.4-.6 1-1.1 1.999-1.1.9 0 1.6.5 1.9 1.1.499-.7 1.199-1.1 2.198-1.1 1.4 0 2.3.9 2.3 2.5v4.1h-1.5v-3.7c0-1-.5-1.6-1.3-1.6s-1.4.6-1.4 1.6V77h-1.498v-3.7c0-1-.5-1.6-1.3-1.6s-1.4.8-1.4 1.7V77h-1.499v-6.4z'
    ></path>
    <path
      fill='#242934'
      fillRule='evenodd'
      d='M28.288 77.1v-1c.4.6 1.1 1.1 2.199 1 1.5 0 2.998-1.2 2.998-3.3 0-2.1-1.499-3.3-2.998-3.3-1.1 0-1.7.5-2.2 1.2v-3.5h-1.499v8.9h1.5zm1.899-5.3c1 0 1.8.8 1.8 2.1 0 1.2-.9 2-1.8 2.1-1 0-1.9-.9-1.9-2.1 0-1.3.9-2.1 1.9-2.1zM37.784 70.5c-1.9 0-3.199 1.5-3.199 3.4 0 2 1.5 3.3 3.498 3.3 1.1 0 2-.5 2.6-1.2l-.9-.8c-.5.5-1 .8-1.7.8-1 0-1.699-.5-1.899-1.6h4.698V74c0-1.9-1-3.5-3.098-3.5zm0 1.2c.9 0 1.499.7 1.599 1.7h-3.299c.2-1 .8-1.7 1.7-1.7z'
      clipRule='evenodd'
    ></path>
    <path
      fill='#242934'
      d='M42.382 70.6h1.499v1.5c.4-1 1.1-1.6 2.299-1.6v1.6h-.1c-1.3 0-2.2.8-2.2 2.6v2.5h-1.498v-6.6zM12.495 29.9h1.399l4.598 5.9v-5.9h1.5v8.6h-1.3l-4.698-6.1v6.1h-1.5v-8.6z'
    ></path>
    <path
      fill='#242934'
      fillRule='evenodd'
      d='M24.19 34.5c-1.6 0-2.7.7-2.7 2.1 0 1.3 1.1 2 2.3 1.9 1 0 1.699-.4 2.099-.9v.8h1.4v-3.8c0-.9-.2-1.5-.7-2-.4-.4-1.1-.7-2.1-.7s-1.699.2-2.399.5l.4 1.2c.5-.2 1.1-.4 1.8-.4.999 0 1.599.5 1.599 1.4v.2c-.5-.2-1-.3-1.7-.3zm1.699 1.2v.4c0 .8-.7 1.4-1.7 1.4-.8 0-1.299-.4-1.299-1 0-.7.5-1.1 1.5-1.1.458 0 .857.117 1.199.217.105.03.205.06.3.083z'
      clipRule='evenodd'
    ></path>
    <path
      fill='#242934'
      d='M29.087 32h1.5v1c.4-.6 1-1.1 1.999-1.1.9 0 1.6.5 1.899 1.1.5-.7 1.2-1.1 2.199-1.1 1.4 0 2.299.9 2.299 2.5v4.1h-1.5v-3.7c0-1-.5-1.6-1.299-1.6-.8 0-1.4.6-1.4 1.6v3.6h-1.498v-3.7c0-1-.5-1.6-1.3-1.6s-1.4.6-1.4 1.6v3.6h-1.499V32z'
    ></path>
    <path
      fill='#242934'
      fillRule='evenodd'
      d='M43.581 31.8c-1.9 0-3.199 1.5-3.199 3.4 0 2 1.4 3.4 3.499 3.3 1.1 0 2-.5 2.599-1.2l-.9-.8c-.5.5-1 .8-1.7.8-.999 0-1.698-.5-1.898-1.6h4.698v-.4c0-1.9-1-3.5-3.099-3.5zm0 1.3c.9 0 1.5.7 1.6 1.7h-3.3c.2-1 .8-1.7 1.7-1.7z'
      clipRule='evenodd'
    ></path>
  </svg>
);

export default FrontOfPhotoCard;
