import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import {
  Header,
  Svg,
  Spacer,
  Box,
  Quickfeed,
  pdfActions,
  formMessages,
} from '@rmstransactions/components';
import {
  tokens,
  ContentContainer,
  Heading,
  TextLink,
  Button,
  BrandIconPersonalDataSecurity,
  InPageAlert,
  IconPrint,
  FormInput,
} from '@snsw/react-component-library';
import { Col, Row } from '@rmstransactions/components/Styled';
import {
  Card,
  CardTitle,
} from '@snsw/react-component-library/build/Components';
import {
  HeaderContentContainer,
  PrintOrDowloadCardContainer,
  FormEmailWrapper,
  ButtonLinkStyle,
  InWarningUnorderedWrapper,
  InPageAlertHeaderOverride,
  PrintOrDownloadWrapper,
  ListWrapper,
  FlexWithGapWrapper,
  InWarningList,
  MediumFont,
} from './AlmostDonePage.style';
import {
  receiptFileName,
  sessionStorageKeyForReceiptUUID,
} from 'constants/constants';
import { confirmationSliceActions } from '../../redux/slice/confirmation';
import { handleConfirmationDetails } from './AlmostDonePageHelper';
import {
  RoutePathParams,
  addSpacesAfterCommas,
  handleScrollToTop,
  invokeSilentLoginIfSessionNotActive,
} from '../../utils/helpers/helpers';
import {
  getInterimDrivingLicence,
  interceptors,
} from 'services/ReplaceLicenceService';
import { IGetInterimDrivingLicencePDFResponse } from 'interfaces/api/response/IGetInterimDrivingLicence';
import { useStoreDispatch, useStoreSelector } from 'redux/store';
import { interimLicenceSliceActions } from 'redux/slice/interimLicence';
import { capitalise } from 'utils/helpers/helpers';
import { DIGITAL_DRIVER_LICENCE_URL } from 'constants/constants';
import { ButtonWithUrl, serviceCenterPhoneNumber } from 'utils/shared/shared';
import {
  downloadDigitalDriverLicenceLink,
  needHelpDescriptionWithPlease,
  serviceCenterLink,
} from 'utils/helpers/ErrorHandlers/helpers/errorContentDetails';
import {
  checkForWarningsAndErrors,
  checkForWarningsAndErrorsInSystemMessages,
} from 'utils/helpers/checkForWarningsAndErrors';
import { IDriveMessageDetail } from '../../interfaces/api/response/IRLConfirmationResponse';
import ActiveLicenceAlert from './components/ActiveLicenceAlert';
import { EmailNotificationError } from 'replace-licence/src/pages/AlmostDonePage/components/EmailNotificationError/EmailNotificationError';
import EmailNotificationSuccess from 'replace-licence/src/pages/AlmostDonePage/components/EmailNotificationSuccess/EmailNotificationSuccess';
import { Config } from 'config/env';
import ConfirmEmailModal from './components/ConfirmEmailModal';
import { ResponseTypeValue } from '../../interfaces/api/request/IPaymentRequest';
import sessionStorageManager from 'utils/session/sessionStorageManager';
import AlertPendingCancellationOrSuspension from './components/AlertPendingCancellationOrSuspension';
import AlertActiveRefusalSanctionWarning from './components/AlertActiveRefusalSanctionWarning';

const { emailUpdateUrl, addressUpdateUrl } = Config;

const getInterimLicence: () => Promise<IGetInterimDrivingLicencePDFResponse> =
  async () => {
    return getInterimDrivingLicence();
  };

const headerContent = () => {
  return (
    <HeaderContentContainer>
      <Spacer mr='1'>
        <Svg.Info
          width={32}
          height={32}
          color={tokens.colors.brand.snswSecondaryBlue}
        />
      </Spacer>
      <span>Almost done...</span>
    </HeaderContentContainer>
  );
};

const FailedDownloadAlert: React.FC<{ title: string }> = ({
  title,
  children,
}) => {
  return (
    <InPageAlert variant='error' title={title}>
      <Spacer mt={0.5}>{children}</Spacer>
    </InPageAlert>
  );
};

const AlmostDonePage: React.FC = () => {
  const abortController = new AbortController();
  const dispatch = useStoreDispatch();
  const [emailPostStatus, setEmailPostStatus] = useState<null | ResponseType>(
    null
  );

  type almostDoneHistoryState = undefined | Record<string, any>;
  const { receiptUUID } = useParams<RoutePathParams>();
  const location = useLocation();
  const state = location?.state as almostDoneHistoryState;

  const almostDoneRedirectReceiptUUID = state?.receiptUUID;
  const providedReceiptUUID =
    receiptUUID ??
    almostDoneRedirectReceiptUUID ??
    sessionStorageManager.getValue(sessionStorageKeyForReceiptUUID);

  const [foundError, setFoundError] = useState(false);

  const {
    confirmation,
    emailAddress,
    mailingAddress,
    receiptNumber,
    transactionDate,
    amountPaid,
    receiptPDFBase64,
    licencePDF,
    licenceCategory,
  } = useStoreSelector((state) => ({
    confirmation: state?.confirmation,
    emailAddress: state?.confirmation?.emailAddress,
    mailingAddress: state?.confirmation?.mailingAddress,
    receiptNumber: state?.confirmation?.receiptNumber,
    transactionDate: state?.confirmation?.transactionDate,
    amountPaid: state?.confirmation?.amountPaid,
    receiptPDFBase64: state?.confirmation?.receiptPDFBase64,
    licencePDF: state?.confirmation?.interimLicencePDFBase64,
    licenceCategory: state?.confirmation?.licenceCategory,
  }));

  const [emailValue, setEmailValue] = useState('');
  const [hasEmailValidationError, setHasEmailValidationError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [showModal, setShowModal] = useState(false);

  const ref = useRef(null);

  const { warningCodes } =
    licenceCategory != null && checkForWarningsAndErrors(licenceCategory);

  const isNoWarning: boolean = warningCodes?.length === 0;

  const handlePrintReceipt = () => {
    if (!receiptPDFBase64) {
      setPrintDownloadError('print');
      return;
    }
    try {
      pdfActions.printBase64PDF(receiptPDFBase64);
      setPrintDownloadError(null);
    } catch {
      setPrintDownloadError('print');
    }
  };

  const history = useHistory();
  const [fulfilmentHasError, setFulfilmentHasError] = useState(false);
  const [printDownloadError, setPrintDownloadError] = useState<
    null | 'download' | 'print'
  >(null);
  const emailAddressRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  const handlePrintInterimDriverLicence = () => {
    try {
      pdfActions.printBase64PDF(licencePDF);
      setPrintDownloadError(null);
    } catch {
      setPrintDownloadError('print');
    }
  };

  const handleDownloadReceipt = () => {
    if (!receiptPDFBase64) {
      setPrintDownloadError('download');
      return;
    }
    try {
      pdfActions.downloadBase64PDF(receiptPDFBase64, receiptFileName);
      setPrintDownloadError(null);
    } catch {
      setPrintDownloadError('download');
    }
  };

  const changeEmailAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailValue(e.target.value);
    setEmailPostStatus(null);
    setHasEmailValidationError(false);
  };

  const validateEmailAddress = (email: string) => {
    if (!email || email === '') {
      setEmailErrorMessage(formMessages.email.isRequired);
      setHasEmailValidationError(true);
      setShowModal(false);
    } else if (!emailAddressRegex.test(email)) {
      setEmailErrorMessage(
        'Your email address must use the format example@email.com'
      );
      setHasEmailValidationError(true);
      setShowModal(false);
    } else {
      setShowModal(true);
      setHasEmailValidationError(false);
    }
  };

  const handleScrollToEmail = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const ifLicPDFErrorExists = (
    confirmationWarningCodes: IDriveMessageDetail[]
  ) => {
    const warningObj = confirmationWarningCodes.filter(function (entry) {
      return (
        entry.identifier === 'UNABLE_GENERATE_INTERIM_LIC_PDF' &&
        entry.severity === 'WARNING'
      );
    });
    return warningObj && warningObj.length === 1;
  };

  const handleInterimLicence = async () => {
    if (abortController.signal.aborted) return;

    const { getInterimDrivingLicencePDFResponse: resp } =
      await getInterimLicence();

    const { errorCodes, warningCodes } =
      checkForWarningsAndErrorsInSystemMessages(resp.systemMessages.message);

    if (errorCodes.length > 0) {
      dispatch(
        interimLicenceSliceActions.setSystemMessagesWithBusinessError({
          errorCodes,
          warningCodes,
        })
      );
      if (errorCodes.includes('UNABLE_GENERATE_INTERIM_LIC_PDF')) {
        setFulfilmentHasError(true);
      } else {
        setFoundError(true);
        return;
      }
    } else {
      const licencePDF = resp?.responseDetails?.licencePDF;

      // If not synchronised, then use it to update confirmation PDF
      if (licencePDF) {
        dispatch(
          confirmationSliceActions.setConfirmationDetails({
            confirmationDetails: {
              ...confirmation,
              interimLicencePDFBase64: licencePDF,
            },
          })
        );
      }
      return licencePDF;
    }
  };

  useEffect(() => {
    let callInProgress = true;

    const handleConfirmation = async (uuid: string) => {
      if (receiptNumber) return;

      const confirmationResponse = await handleConfirmationDetails(uuid);

      let interimLicenceResp;
      if (!confirmationResponse.confirmationData?.interimLicencePDFBase64) {
        // not received, we need to initialize licence and customer details.
        interimLicenceResp = await handleInterimLicence();
      }

      if (callInProgress) {
        dispatch(
          confirmationSliceActions.setConfirmationDetails({
            confirmationDetails: {
              ...confirmationResponse.confirmationData,
              interimLicencePDFBase64:
                confirmationResponse.confirmationData
                  ?.interimLicencePDFBase64 || interimLicenceResp,
            },
          })
        );

        if (
          confirmationResponse?.confirmationData?.messages?.length > 0 &&
          ifLicPDFErrorExists(confirmationResponse?.confirmationData?.messages)
        )
          setFulfilmentHasError(true);
      }
    };

    if (foundError) {
      history.push('/error');
    } else if (providedReceiptUUID && !licencePDF) {
      handleConfirmation(providedReceiptUUID);
    }

    return () => {
      callInProgress = false;
    };
  }, [foundError, providedReceiptUUID]);

  useEffect(() => {
    handleScrollToTop();
    return () => {
      abortController.abort();
    };
  }, [mailingAddress]);

  useEffect(() => {
    //Invokes access error page when logged out in multi-tab
    interceptors(history);
    const currentUrl = window.location.href;
    if (!currentUrl.includes('/view-receipt')) {
      invokeSilentLoginIfSessionNotActive();
    }
  }, []);

  return (
    <>
      <Header data-testid='header' title={headerContent()} />
      {showModal && (
        <ConfirmEmailModal
          emailValue={emailValue}
          setShowModal={setShowModal}
          setEmailPostStatus={setEmailPostStatus}
        />
      )}
      <ContentContainer>
        <Row>
          <Col xl={8} lg={8}>
            {isNoWarning && (
              <InPageAlertHeaderOverride
                variant='warning'
                title='To legally drive you must either:'
              >
                <InWarningUnorderedWrapper>
                  <InWarningList>
                    carry your new{' '}
                    <MediumFont>NSW Driver Licence card</MediumFont>
                  </InWarningList>
                  <InWarningList>
                    print and carry your{' '}
                    <MediumFont>Interim Driver Licence</MediumFont> (Driver
                    Licence receipt),
                  </InWarningList>
                  <InWarningList>
                    or download your{' '}
                    <TextLink
                      href={DIGITAL_DRIVER_LICENCE_URL}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <MediumFont>Digital Driver Licence</MediumFont>
                    </TextLink>
                  </InWarningList>
                </InWarningUnorderedWrapper>
                <Spacer mt={1}>
                  <p>
                    Your Interim Driver Licence is valid for two months from the
                    issue date.
                  </p>
                </Spacer>
              </InPageAlertHeaderOverride>
            )}

            {licenceCategory && (
              <ActiveLicenceAlert licenceCategory={licenceCategory} />
            )}

            <AlertPendingCancellationOrSuspension
              licenceCategory={licenceCategory}
            />
            <AlertActiveRefusalSanctionWarning
              licenceCategory={licenceCategory}
            />
            <Heading level={2}>Summary</Heading>
            <Spacer>
              Your new NSW Driver Licence card will be mailed to{' '}
              <b>{addSpacesAfterCommas(mailingAddress)}</b> and should arrive
              within 15 business days.{' '}
              <TextLink
                href={addressUpdateUrl}
                target='_blank'
                rel='noopener noreferrer'
              >
                Update your address
              </TextLink>
            </Spacer>
            <Spacer mt={2} mb={2}>
              Your Interim Driver Licence (Driver Licence receipt) has been
              emailed to <b>{emailAddress}</b>.{' '}
              <ButtonLinkStyle>
                <Button
                  variant='link'
                  className='buttonLink'
                  data-testid='send-to-another-email'
                  onClick={handleScrollToEmail}
                >
                  Send to another email
                </Button>
              </ButtonLinkStyle>
            </Spacer>
            <Spacer>Receipt number: {receiptNumber}</Spacer>
            <Spacer mt={1} mb={1}>
              Date of transaction: {transactionDate}
            </Spacer>
            <Spacer>
              <b>Total amount paid: ${amountPaid?.toFixed(2) || 0.0}</b>
            </Spacer>
            <Heading level={2}>Please choose one of the following:</Heading>
            <PrintOrDowloadCardContainer>
              {fulfilmentHasError && (
                <Spacer mb={2.5}>
                  <InPageAlert
                    variant='error'
                    title='We could not generate your interim Driver Licence (Driver Licence receipt)'
                  >
                    <Spacer mt={0.5}>
                      {
                        <div>
                          Please try again, call us on{' '}
                          {serviceCenterPhoneNumber} or visit a{' '}
                          {serviceCenterLink}
                          . <br />
                          <br />
                          Alternatively your NSW{' '}
                          {downloadDigitalDriverLicenceLink} is available
                          through the Service NSW app.
                        </div>
                      }
                    </Spacer>
                  </InPageAlert>
                </Spacer>
              )}
              <PrintOrDownloadWrapper>
                <Spacer mr={1.5}>
                  <IconPrint size='lg' />
                </Spacer>
                <Button onClick={handlePrintInterimDriverLicence}>
                  Print Interim Driver Licence
                </Button>
              </PrintOrDownloadWrapper>
              <ListWrapper>
                <li>
                  <b>You must print and sign</b> your Interim Driver Licence and{' '}
                  <b>carry</b> it while driving
                </li>
                <li>
                  Please contact us on {serviceCenterPhoneNumber} if you have
                  not received your Interim Driver Licence by email within 24
                  hours
                </li>
                <li>
                  Your new NSW Driver Licence card may take up to 15 business
                  days to arrive by mail
                </li>
                <li>
                  Print now or print the pdf emailed to
                  <b> {emailAddress}</b>
                  <br />
                  <ButtonLinkStyle>
                    <Button
                      variant='link'
                      className='buttonLink'
                      onClick={handleScrollToEmail}
                    >
                      Send to another email
                    </Button>
                  </ButtonLinkStyle>
                </li>
              </ListWrapper>
            </PrintOrDowloadCardContainer>
            <Box
              height={59}
              fontSize={18}
              display='flex'
              alignItems='center'
              justifyContent='center'
            >
              <b>-OR-</b>
            </Box>
            <PrintOrDowloadCardContainer>
              <PrintOrDownloadWrapper>
                <Spacer mr={1.5}>
                  <Svg.Phone width='48px' height='48px' />
                </Spacer>
                <ButtonWithUrl
                  href={DIGITAL_DRIVER_LICENCE_URL}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Download Digital Driver Licence
                </ButtonWithUrl>
              </PrintOrDownloadWrapper>
              <ListWrapper>
                <li>
                  Download or update the Service NSW app on your mobile device
                </li>
                <li>Keep your Digital Driver Licence with you while driving</li>
                <li>
                  The NSW Digital Driver Licence is only valid within NSW. Carry
                  your Interim Driver Licence or physical NSW Driver Licence
                  card while driving interstate or overseas.
                </li>
              </ListWrapper>
            </PrintOrDowloadCardContainer>
            <Heading level={3}>Keep a receipt for your records</Heading>
            <Box display='flex' mb={1} ref={ref}>
              <Spacer mr={1}>
                <Svg.Print width='26px' height='26px' />
              </Spacer>
              <ButtonLinkStyle>
                <Button
                  variant='link'
                  className='buttonLink'
                  data-testid='print-your-receipt'
                  onClick={handlePrintReceipt}
                >
                  Print your receipt
                </Button>
              </ButtonLinkStyle>
            </Box>
            <Box display='flex' mb={2}>
              <Spacer mr={1}>
                <Svg.Pdf width='26px' height='26px' />
              </Spacer>
              <ButtonLinkStyle>
                <Button
                  variant='link'
                  className='buttonLink'
                  data-testid='download-receipt-button'
                  onClick={handleDownloadReceipt}
                >
                  Download transaction receipt
                </Button>
              </ButtonLinkStyle>
            </Box>
            <Box display='flex' mb={0.5}>
              <Spacer mr={1}>
                <Svg.Email width='24px' />
              </Spacer>
              <span>
                Email Interim Driver Licence (Driver Licence receipt) to this
                address
              </span>
            </Box>
            <FormEmailWrapper>
              <FormInput
                name='email'
                data-testid='email'
                label='Send receipt to this email address'
                value={emailValue}
                onChange={(e: any) => changeEmailAddress(e)}
                hasError={hasEmailValidationError}
                errorMessage={emailErrorMessage}
              />
              <Button
                variant='secondary'
                disabled={hasEmailValidationError}
                data-testid='sendEmailButton'
                onClick={() => validateEmailAddress(emailValue)}
              >
                Send
              </Button>
            </FormEmailWrapper>
            <EmailNotificationError
              visible={emailPostStatus === ResponseTypeValue.ERROR}
              data-testid='errorNotification'
            />
            <EmailNotificationSuccess
              visible={emailPostStatus === ResponseTypeValue.SUCCESS}
              emailAddress={emailValue}
              data-testid='successNotification'
            />
            <Spacer mt={3.5}>
              <Card>
                <FlexWithGapWrapper>
                  <BrandIconPersonalDataSecurity size='lg' />
                  <CardTitle>Keep your details up to date</CardTitle>
                </FlexWithGapWrapper>
                <Spacer mt={1} mb={2}>
                  Keep your mail and email addresses up to date with Transport
                  for NSW for important activity related to your licences and
                  registrations.
                </Spacer>
                <Spacer mb={[1.5, 0.5]}>
                  <FlexWithGapWrapper gap='0.5rem'>
                    <b>{addSpacesAfterCommas(mailingAddress)}</b>
                    <TextLink
                      href={addressUpdateUrl}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Update your mail address
                    </TextLink>
                  </FlexWithGapWrapper>
                </Spacer>
                <FlexWithGapWrapper gap='0.5rem'>
                  <b>{emailAddress}</b>
                  <TextLink
                    href={emailUpdateUrl}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Update your email address
                  </TextLink>
                </FlexWithGapWrapper>
              </Card>
            </Spacer>
            <Spacer mt={3.5}>
              <Quickfeed
                sourceApplication='Replace-Driver-Licence'
                label='How was your experience?'
                environment={process.env.REACT_APP_TUTD}
              />
            </Spacer>
          </Col>
        </Row>
        {printDownloadError && (
          <FailedDownloadAlert
            title={capitalise(printDownloadError) + 'failed'}
          >
            There was a problem {printDownloadError + 'ing'} your report, please
            try again. {needHelpDescriptionWithPlease} to check if you&apos;re
            eligible for a replacement.
          </FailedDownloadAlert>
        )}
      </ContentContainer>
    </>
  );
};

export default AlmostDonePage;
